<template>
    <div>
        <van-nav-bar class="cart-title" title="我的学习周报" fixed left-arrow @click-left="onBack"/>
        <van-row>
            <van-col span="6">
                <img :src="avatarSrc">
            </van-col>
            <van-col span="18">
                <van-tag round size="large" color="#f5f5f5" text-color="#656466" class="nickname">{{nickname}}</van-tag>
                <van-grid :column-num="3">
                    <van-grid-item>
                        <van-cell class="shuju">
                            <template #title>
                                <van-tag round color="#e3eef9" text-color="#1989fa">我的</van-tag><br>
                                <span><b>{{questionDay}}</b>天</span>
                            </template>
                            <template #label>
                                累计听课
                            </template>
                        </van-cell>
                    </van-grid-item>
                    <van-grid-item>
                        <van-cell class="shuju">
                            <template #title>
                                <van-tag round color="#e3eef9" text-color="#1989fa">我的</van-tag><br>
                                <span><b>{{count}}</b>h</span>
                            </template>
                            <template #label>
                                听课时长
                            </template>
                        </van-cell>

                    </van-grid-item>
                    <van-grid-item>
                        <van-cell class="shuju">
                            <template #title>
                                <van-tag round color="#e3eef9" text-color="#1989fa">网校学员</van-tag><br>
                                <span><b>{{branchAvg}}</b>h</span>
                            </template>
                            <template #label>
                                平均时长
                            </template>
                        </van-cell>

                    </van-grid-item>
                </van-grid>
            </van-col>
        </van-row>



        <div class="echarts_home">
            <!-- 这个 div 就会被解析为 echarts图 -->
            <div class="barChart" ref="barChart"></div>
        </div>

<!--        <div class="signOut">-->
<!--            <van-button round type="info" icon="share-o" >分享给好友</van-button>-->
<!--        </div>-->
    </div>
</template>

<script>
    import {Toast} from "vant";
    import {commodityStudyReport} from "@/request/api";
    export default {
        metaInfo() {
            return {
                title: '学习周报', //设置页面title
            }
        },
        data() {
            return {
                // title: this.getBranchCompany(),
                avatarSrc: "http://cdnoss.ksyt.com.cn/wxImages/assets/images/avatar.png",
                nickname: "账号名称在这里",
                questionDay: 0,
                count: 0,
                branchAvg: 0
            }
        },
        created() {
            this.id = this.$route.query.id;
            this.getStudyReport();
        },
        mounted() {
            this.initBarChart();
        },
        methods: {
            onBack() {
                history.back();
                window.android.back();
            },
            change(){},
            onChange(){},
            getStudyReport() {
                let that = this;
                let info = that.getUserDetail();
                if(info.headPortrait) {
                    that.avatarSrc = info.headPortrait
                }
                that.nickname = info.username
                let id = that.id;
                commodityStudyReport({id})
                    .then(res => {
                        if (res.data.code == 1) {
                            that.branchAvg = res.data.data.branchAvg;
                            console.log(res.data.data.categories)
                            that.count = res.data.data.count;
                            that.questionDay = res.data.data.questionDay;
                            let myChart = that.$echarts.init(that.$refs.barChart);
                            let option = {
                                title: {
                                    text: '我的听课时长'
                                },
                                xAxis: {
                                    type: 'category',
                                    data: res.data.data.categories
                                },
                                yAxis: {
                                    type: 'value'
                                },
                                series: [
                                    {
                                        data: res.data.data.questionData,
                                        type: 'line',
                                        smooth: true
                                    }
                                ]
                            };
                            myChart.setOption(option);
                        } else {
                            Toast.fail(res.data.msg)
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            },
            
        },
    }
</script>

<style lang="less" scoped>
    .echarts_home {
        //   width: 500px;
        height: 30rem;
        margin: auto;
        padding: 2rem 0rem 2rem 2rem;
        // border: 0.1rem solid lightcoral;

        //  宽高是必须给的，可以给百分比、具体的像素等....
        .barChart {
            width: 100%;
            height: 100%;
        }
    }

    .van-row {
        height: 18rem;
        padding: 6rem 2rem 0.5rem 2rem;
        background-image: url('http://cdnoss.ksyt.com.cn/wxImages/assets/images/tbg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        color: #fff;
        display: flex;
        align-items: center;

        .van-col {
            line-height: 6rem;

            img {
                width: 10rem;
                border-radius: 50%;
                display: block;
            }

            .nickname{
                font-size: 2rem !important;
            }
        }
    }

    .van-grid {
        // padding: 1rem 0.5rem;

        .van-grid-item {
            // padding: 0 0.5rem;
            border-radius: 0.5rem;
        }
        .van-icon {
            font-size: 4.4rem;
            padding: 1rem;
        }

        .textTitle {
            margin-bottom: 1.5rem;
            font-size: 1.6rem;
            color: #333333;
        }
    }

    .signOut {
        text-align: center;
        padding-top: 2rem;
        padding-bottom: 10rem;

        .van-button {
            width: 90%;
        }
    }
    .shuju {
        height: 10rem;
        margin: 0 auto;
        text-align: center;
        background: none;
        span{
            line-height:4rem;
        }
        b {
            font-weight: 600;
            font-size: 2.8rem;
        }
        .van-cell__label {
            font-size: 1.4rem;
            line-height: 2rem;
        }
    }


</style>
