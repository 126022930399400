<template>
  <div>
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        课程协议
      </template>
    </van-nav-bar>
    <div class="courseAgreement" style="padding: 6rem 0 8rem 0;">
<!--      <van-empty image-size="30rem" description="暂无课程协议"/>-->
          <div style="padding: 1rem;">
              <van-card
                class="shop-card"
                :desc="orderInfo.commodity_content"
                :title="orderInfo.title"
                :thumb="orderInfo.photopath"
              >
                <template #tags>
                  <van-tag round class="vantag">课程有效期：{{orderInfo.finishtime}}</van-tag>
                </template>
              </van-card>
              <div class="vanform">
                <van-form @submit="onSubmit">
                  <!-- 通过 pattern 进行正则校验 -->
                  <van-field
                    v-model="userInfo.userid"
                    required
                    disabled
                    name="userid"
                    label="听课账号"
                    placeholder="请输入听课账号"
                    :rules="[{ required: true, }]"
                  />
                  <van-field
                          v-model="userInfo.username"
                          required
                          name="username"
                          placeholder="请输入真实姓名"

                          :rules="[{ required: true, }]"
                  >
                    <template #label><span style="font-weight:600">甲方姓名</span></template>
                  </van-field>
                  <van-field
                    v-model="userInfo.id_card"
                    type="number"
                    required
                    name="id_card"
                    label="身份证号"
                    placeholder="请输入身份证件号码"
                    :rules="[{ required: true,}]"
                  />
                  <van-field
                    v-model="userInfo.tel_number"
                    type="tel"
                    required
                    name="tel_number"
                    label="手机号码"
                    placeholder="请输入手机号码"
                    :rules="[{ required: true, }]"
                  />

<!--                  <van-field-->
<!--                    v-model="sms"-->
<!--                    required-->
<!--                    center-->
<!--                    clearable-->
<!--                    label="短信验证码"-->
<!--                    placeholder="请输入短信验证码"-->
<!--                  >-->
<!--                    <template #button>-->
<!--                      <van-button size="small" type="primary" style="display: flex;">发送验证码</van-button>-->
<!--                    </template>-->
<!--                  </van-field>-->

                  
                  <div class="agreecontent">
                    <van-cell>
                      <template #title>
                        乙方：<span style="color:#656466">{{info.corporate_name}}</span>
                      </template>
                      <template #label>
                        <div>地址：<span style="color:#969799">{{info.company_address}}</span></div>
                        <div>电话：<span style="color:#969799">{{info.phone}}</span></div>
                      </template>
                    </van-cell>
                  
                    <van-cell center title="" >
                      <template #label>
                        甲、乙双方本着平等互利的原则，经友好协商，依照相关法律法规，同意按照本协议的条款，由乙方所拥有的手机网校为甲方提供{{orderInfo.title}}考前辅导服务，具体条款如下：
                      </template>
                    </van-cell>

                    <van-cell center title="" >
                      <template #label ><div v-html="info.term"></div></template>
                    </van-cell>
                    
                    <!-- <van-field
                      v-model="sms"
                      required
                      center
                      clearable
                      label="甲方签名"
                      placeholder="请输入真实姓名"
                      :rules="[{ required: true, }]"
                    >
                      <template #button>
                        <van-button round size="small" type="warning">申请续读</van-button>
                      </template>
                    </van-field> -->

                    <van-cell center title="甲方签名" >
                      <template #label>
                        
                      <!-- <section class="signature"> -->
                        <!-- <div class="signatureBox">
                            <div class="canvasBox" ref="canvasHW">
                                <canvas ref="canvasF" @touchstart='touchStart' @touchmove='touchMove' @touchend='touchEnd' @mousedown="mouseDown" @mousemove="mouseMove" @mouseup="mouseUp"></canvas>
                                  <van-row  class="btnBox">
                                      <van-col span="12">
                                        <van-button round size="small" type="danger" @click="overwrite">重写</van-button>
                                      </van-col>
                                      <van-col span="12">
                                        <van-button round size="small" type="primary"  @click="commit">提交签名</van-button>
                                      </van-col>
                                   </van-row>
                            </div>
                        </div> -->
                        <!-- <img class="imgCanvas" :src="imgUrl"> -->
                        <!-- </section> -->
                      </template>
                      <template #right-icon>
                        <!-- <van-icon :name="shenhe" class="shenhe"></van-icon> -->
                        <van-button round size="small" type="primary" @click="showPopup">提交签名</van-button>
                      </template>
                    </van-cell>

                    <van-cell center title="乙方（盖章）" >
                      <template #label>
                        {{info.corporate_name}}
                      </template>
                      <template #right-icon>
                        <van-icon :name="info.official_seal" class="shenhe"></van-icon>
                      </template>
                    </van-cell>

                    <van-cell center v-if="!agreementStatus">
                      <template #title v-if="status == 1">
                        协议签订：学员线上签署<span style="color:#07c160;padding-left: 1rem;">申请已通过</span>
                      </template>
                      <template #title v-else>
                        协议签订：学员线上签署<span style="color:#07c160;padding-left: 1rem;">申请审核中</span>
                      </template>
                      <template #label>
                        <div>签订时间：{{signatureCreateTime}}</div>
                      </template>
                    </van-cell>

                  </div>



                  <div class="subbtn" v-if="agreementStatus">
                    <van-button round block type="info" size="large" native-type="submit">提交</van-button>
                  </div>
                </van-form>
              </div>
          </div>
          <van-popup
            v-model="show"
            closeable
            position="bottom"
            :style="{ height: '100%' }"
          >
          <Canvas />
          </van-popup>
    </div>
  </div>
</template>

<script>
  import {commodityAttributeAgree, commodityAttributeAgreePost} from '@/request/api'
  import {Toast} from "vant";
import Canvas from '@/components/Canvas'
export default {
  components: {
    Canvas,
  },
  name: 'courseAgreement',
  metaInfo: {
    title: '课程协议', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      show: false,
      // stageInfo:'',
      //   imgUrl:'',
      //   client: {},
      //   points: [],
      //   canvasTxt: null,
      //   startX: 0,
      //   startY: 0,
      //   moveY: 0,
      //   moveX: 0,
      //   endY: 0,
      //   endX: 0,
      //   w: null, 
      //   h: null,
      //   isDown: false,
      //   isViewAutograph: this.$route.query.isViews > 0,
      //   contractSuccess: this.$route.query.contractSuccess,
      // active:'',
      tel: '',
      text: '',
      text1: '',
      number: '',
      sms: '',
      shenhe: "http://cdnoss.ksyt.com.cn/wxImages/ksyt_gongzhang.png",
      orderId:'',
      commodityId: '',
      info: [],
      orderInfo: [],
      userInfo: [],
      agreementStatus: false,
      src: '',
      status: 1,
      signatureCreateTime: '',
      pwd: ''
    }
  },
  created() {
    let oid = this.$route.query.oid
    let commodityId = this.$route.query.id
    this.orderId = oid;
    this.commodityId = commodityId;
    this.getCommodityAttributeAgree();
  },
  mounted() {
      // let canvas = this.$refs.canvasF
      // canvas.height = this.$refs.canvasHW.offsetHeight - 50
      // canvas.width = this.$refs.canvasHW.offsetWidth - 5
      // this.canvasTxt = canvas.getContext('2d')
      // this.stageInfo = canvas.getBoundingClientRect()
  },

  methods: {
    showPopup() {
      this.show = true;
    },
    cancelMask() {
      this.showTip = false
    },
      //mobile
      // touchStart(ev) {
      //   console.log('touchStart')
      //   ev = ev || event
      //   ev.preventDefault()
      //   if (ev.touches.length == 1) {
      //     let obj = {
      //       x: ev.targetTouches[0].clientX,
      //       y: ev.targetTouches[0].clientY,
      //     }
      //     console.log(obj)
      //     this.startX = obj.x
      //     this.startY = obj.y
      //     this.canvasTxt.beginPath()
      //     this.canvasTxt.moveTo(this.startX, this.startY)
      //     this.canvasTxt.lineTo(obj.x, obj.y)
      //     this.canvasTxt.stroke()
      //     this.canvasTxt.closePath()
      //     this.points.push(obj)
      //   }
      // },
      // touchMove(ev) {
      //   ev = ev || event
      //   ev.preventDefault()
      //   if (ev.touches.length == 1) {
      //     let obj = {
      //       x: ev.targetTouches[0].clientX - this.stageInfo.left,
      //       y: ev.targetTouches[0].clientY - this.stageInfo.top
      //     }
      //     this.moveY = obj.y
      //     this.moveX = obj.x
      //     this.canvasTxt.beginPath()
      //     this.canvasTxt.moveTo(this.startX, this.startY)
      //     this.canvasTxt.lineTo(obj.x, obj.y)
      //     this.canvasTxt.stroke()
      //     this.canvasTxt.closePath()
      //     this.startY = obj.y
      //     this.startX = obj.x
      //     this.points.push(obj)
      //   console.log('touchMove')
      //   }
      // },
      // touchEnd(ev) {
      //   ev = ev || event
      //   ev.preventDefault()
      //   if (ev.touches.length == 1) {
      //     let obj = {
      //       x: ev.targetTouches[0].clientX - this.stageInfo.left,
      //       y: ev.targetTouches[0].clientY - this.stageInfo.top
      //     }
      //     this.canvasTxt.beginPath()
      //     this.canvasTxt.moveTo(this.startX, this.startY)
      //     this.canvasTxt.lineTo(obj.x, obj.y)
      //     this.canvasTxt.stroke()
      //     this.canvasTxt.closePath()
      //     this.points.push(obj)
      //   console.log('touchEnd')
      //   }
      // },
      //pc
      // mouseDown(ev) {
      //   console.log('mouseDown')
      //   ev = ev || event
      //   ev.preventDefault()
      //   if (1) {
      //     let obj = {
      //       x: ev.offsetX,
      //       y: ev.offsetY
      //     }
      //     this.startX = obj.x
      //     this.startY = obj.y
      //     this.canvasTxt.beginPath()
      //     this.canvasTxt.moveTo(this.startX, this.startY)
      //     this.canvasTxt.lineTo(obj.x, obj.y)
      //     this.canvasTxt.stroke()
      //     this.canvasTxt.closePath()
      //     this.points.push(obj)
      //     this.isDown = true
      //   }
      // },
      // mouseMove(ev) {
      //   console.log('mouseMove')
      //   ev = ev || event 
      //   ev.preventDefault()
      //   if (this.isDown) {
      //     let obj = {
      //       x: ev.offsetX,
      //       y: ev.offsetY
      //     }
      //     this.moveY = obj.y
      //     this.moveX = obj.x
      //     this.canvasTxt.beginPath()
      //     this.canvasTxt.moveTo(this.startX, this.startY)
      //     this.canvasTxt.lineTo(obj.x, obj.y)
      //     this.canvasTxt.stroke()
      //     this.canvasTxt.closePath()
      //     this.startY = obj.y
      //     this.startX = obj.x
      //     this.points.push(obj)
      //   }
      // },
      // mouseUp(ev) {
      //   console.log('mouseUp')
      //   ev = ev || event
      //   ev.preventDefault()
      //   if (1) {
      //     let obj = {
      //       x: ev.offsetX,
      //       y: ev.offsetY
      //     }
      //     this.canvasTxt.beginPath()
      //     this.canvasTxt.moveTo(this.startX, this.startY)
      //     this.canvasTxt.lineTo(obj.x, obj.y)
      //     this.canvasTxt.stroke()
      //     this.canvasTxt.closePath()
      //     this.points.push(obj)
      //     this.points.push({x: -1, y: -1})
      //     this.isDown = false
      //   }
      // },
      //重写
      // overwrite() {
      //   this.canvasTxt.clearRect(0, 0, this.$refs.canvasF.width, this.$refs.canvasF.height)
      //   this.points = []
      // },
      //提交签名
      // commit() {
      //   this.imgUrl=this.$refs.canvasF.toDataURL();
      //   console.log(this.$refs.canvasF.toDataURL()) //签名img回传后台
      // },

      
    onSubmit(values) {
      console.log('submit', values);
      let username = values["username"];
      let id_card = values["id_card"];
      let tel_number = values["tel_number"];
      let agreement = values["agreement2"];
      if(username == '') {
        Toast.fail('请输入用户名');
        return false;
      }
      if(agreement == '') {
        Toast.fail('请阅读并同意服务条款和协议');
        return false;
      }
    },

    onBack() {
      history.back();
      window.android.back();
    },
    getCommodityAttributeAgree() {
      let that = this;
      let orderId = that.orderId
      let commodityId = that.commodityId
      commodityAttributeAgree({commodityId,orderId})
              .then(res => {
                if (res.data.code == 1) {
                  console.log(res.data.data);
                  that.info = res.data.data.info;
                  that.orderInfo = res.data.data.orderInfo;
                  that.userInfo = res.data.data.userInfo;
                  that.src = res.data.data.signature;
                  that.agreementStatus = res.data.data.agreementStatus;
                  that.signatureCreateTime = res.data.data.signatureCreateTime;
                  that.status = res.data.data.status;
                }
              })
              .catch(err => {
                console.log("error");
                console.log(err);
              })
    },
    commodityAttributeAgreePost() {
      let that = this;
      let orderId = that.orderId
      let commodityId = that.commodityId
      commodityAttributeAgreePost({commodityId,orderId})
              .then(res => {
                if (res.data.code == 1) {
                  console.log(res.data.data);
                  that.info = res.data.data.info;
                  that.orderInfo = res.data.data.orderInfo;
                  that.userInfo = res.data.data.userInfo;
                  that.src = res.data.data.signature;
                  that.agreementStatus = res.data.data.agreementStatus;
                  that.signatureCreateTime = res.data.data.signatureCreateTime;
                  that.status = res.data.data.status;
                }
              })
              .catch(err => {
                console.log("error");
                console.log(err);
              })
    }
  },
}
</script>
 
<style lang = "less" >
    .courseAgreement .courseData {
        margin: 0.5rem 1rem 1rem 1rem;
        border-radius: 1rem;
        background-color: #ffffff;
        padding-bottom: 0.5rem;
    }
    .courseAgreement .courseData .van-cell {
        border-radius: 1rem !important;
        padding: 1.5rem 1.5rem 1.5rem .5rem;
    }
    .courseAgreement .courseData .van-cell::after {
        border-width: 0 !important;
    }

    .courseAgreement .courseData .answering .custom-title{
        // font-size: 1.8rem;
        font-weight: 600;
        padding-right: 1rem;
        line-height: 3rem;
    }
    .courseAgreement .courseData .answering .van-icon {
        font-size: 3.6rem;
        padding-right: 1rem;
    }
    .courseAgreement .courseData .answering .van-cell__label {
        color: #646466;
        font-size: 1.8rem;
        line-height: 2.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;  //使用自适应布局
        -webkit-line-clamp: 2;  //设置超出行数，要设置超出几行显示省略号就把这里改成几
        -webkit-box-orient: vertical;
    }
    .courseAgreement .courseData .project_tag{
      position: absolute;
      left: 1rem;
      // float: left;
      padding: 0.3rem 0.8rem !important;
      font-size: 1.5rem !important;
      z-index: 99999;
      border-top-left-radius: 1rem;
    }
    .courseAgreement .courseData .reply_tag{
      background-color: #f5f5f5;
      border-radius: 3rem;
      // padding-left: 0.3rem;
      height: 3.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // width: 90%;
      margin: 0 1rem;
    }
    .courseAgreement .courseData .reply_tag .van-tag{
      height: 2.8rem !important;
      // width: 3rem !important;
      border-radius: 3rem;
      padding: 0.1rem 1rem !important;
      font-size: 1.8rem !important;
    }
    .courseAgreement .courseData .reply_tag .reply_text{
      padding-left: 0.2rem;
      font-size: 1.8rem;
      color: #646566;
    }
    .courseAgreement .icon-img{
      width: 8rem;

    }
    .courseAgreement .shop-card {
      padding: 1rem !important;
      border-radius: 1rem;
      min-height: 10rem;
    }
    .courseAgreement .shop-card .van-card__thumb {
        width: 15rem;
        min-height: 10rem;
        margin-right: 0.6rem;
    }
    .courseAgreement .shop-card .van-card__desc {
      line-height: 3rem;
    }
    .courseAgreement .shop-card  .vantag {
      margin-top: 1rem;
      padding: 0.1rem 0.8rem !important;
      line-height: 1.6rem !important;
      font-size: 1.5rem !important;
    }
    .courseAgreement .vanform {
      padding-top: 1rem;
      border-radius: 1rem;
    }
    .courseAgreement .vanform .van-cell {
        min-height: 6rem;
        padding: 0.5rem 1rem !important;
        line-height: 5rem !important;
        font-size: 1.8rem !important;
    }
    .courseAgreement .vanform .van-cell .van-field__label {
      line-height: 5rem;
      padding-left: 1rem;
    }
    .courseAgreement .vanform .van-cell--required::before {
      position: absolute;
      left: 1rem;
      padding-right: 0.5rem;
      color: #ee0a24;
      font-size: 2rem;
      content: '*';
    }
    .courseAgreement .vanform .van-field__error-message {
      color: #ee0a24;
      font-size: 1.4rem;
      text-align: left;
    }
    
    .courseAgreement .agreecontent {
      padding-top: 1rem;
      border-radius: 1rem;
    }
    .courseAgreement .agreecontent .van-cell {
        min-height: 6rem;
        padding: 1.5rem 1rem !important;
        line-height: 5rem !important;
        font-size: 1.8rem !important;
    }
    .courseAgreement .agreecontent .van-cell__label {
        margin-top: 0.5rem;
        color: #656466;
        font-size: 1.8rem;
        line-height: 3.6rem;
    }
    .courseAgreement .agreecontent .shenhe {
        font-size: 8rem;
    }
    .courseAgreement .subbtn {
      position: fixed;
      right: 1rem;
      left: 1rem;
      bottom: 0.5rem;
      background-color: #fff;
    }


    // .signatureBox {
    //   width: 100%;
    //   height: calc(100% - 5rem);
    //   box-sizing: border-box;
    //   overflow: hidden;
    //   background: #fff;
    //   z-index: 100;
    //   display: flex;
    //   flex-direction: column;
    // }
    // .canvasBox {
    //   box-sizing: border-box;
    //   flex: 1;
    //   width: 100%;
    //   height: 6rem;
    // }
    // canvas {
    //   border: 0.1rem solid #7d7d7d;
    //   width: 99%;
    //   height: 100%;
    // }
    // .btnBox {
    //   padding: 1rem;
    //   text-align: center;
    // }
    // .btnBox .van-button {
    //   width: 100%;
    //   font-size: 2rem;
    // }
</style>
