<template>
    <div>
        <van-nav-bar class="cart-title" title="我的做题报告" fixed left-arrow @click-left="onBack"/>
        <van-cell center class="vanCell">
            <template #icon>
                <van-image
                    round
                    width="10rem"
                    height="10rem"
                    :src="avatarSrc"
                />
            </template>
            <template #title><span class="nickname">{{nickname}}</span></template>
            <template #label>
                <van-row>
                    <van-col span="8">
                        <div class="qshuju">
                            <div class="dayBox">{{questionDay}}天</div>
                            <div class="dayTit">累计天数</div>
                        </div>
                    </van-col>
                    <van-col span="8">
                        <div class="qshuju">
                            <div class="dayBox">{{count}}题</div>
                            <div class="dayTit">累计做题</div>
                        </div>
                    </van-col>
                    <van-col span="8">
                        <div class="qshuju">
                            <div class="dayBox">{{correct_rate_count}}</div>
                            <div class="dayTit">正确率</div>
                        </div>
                    </van-col>
                </van-row>
            </template>
        </van-cell>

        <div class="echarts_home">
            <!-- 这个 div 就会被解析为 echarts图 -->
            <div class="barChart" ref="barChart"></div>
        </div>

<!--        <div class="signOut">-->
<!--            <van-button round type="info" icon="share-o" >分享给好友</van-button>-->
<!--        </div>-->
    </div>
</template>

<script>
    import {Toast} from "vant";
    import {studyReport} from "@/request/api";
    export default {
        metaInfo() {
            return {
                title: '做题报告', //设置页面title
            }
        },
        data() {
            return {
                // title: this.getBranchCompany(),
                avatarSrc: "http://cdnoss.ksyt.com.cn/wxImages/assets/images/avatar.png",
                nickname: "账号名称在这里",
                questionDay: 0,
                count: 0,
                correct_rate_count: 0
            }
        },
        created() {
            this.getStudyReport();
        },
        mounted() {
            // this.initBarChart();
        },
        methods: {
            onBack() {
                history.back();
            },
            change(){},
            onChange(){},
            getStudyReport() {
                let that = this;
                let info = that.getUserDetail();
                if(info.headPortrait) {
                    that.avatarSrc = info.headPortrait
                }
                that.nickname = info.username
                studyReport()
                    .then(res => {
                        if (res.data.code == 1) {
                            that.correct_rate_count = res.data.data.correct_rate_count;
                            that.count = res.data.data.count;
                            that.questionDay = res.data.data.questionDay;
                            let myChart = that.$echarts.init(that.$refs.barChart);
                            let option = {
                                title: {
                                    text: '我的做题统计'
                                },
                                xAxis: {
                                    type: 'category',
                                    data: res.data.data.categories
                                },
                                yAxis: {
                                    type: 'value'
                                },
                                series: [
                                    {
                                        data: res.data.data.questionData,
                                        type: 'line',
                                        smooth: true
                                    }
                                ]
                            };
                            myChart.setOption(option);
                        } else {
                            Toast.fail(res.data.msg)
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            },
            initBarChart() {
                let myChart = this.$echarts.init(this.$refs.barChart);
                let option = {
                    title: {
                    text: '我的做题统计'
                },
                xAxis: {
                    type: 'category',
                    data: ['12日', '13日', '14日', '15日', '16日', '17日', '18日']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                    data: [0.5, 0.9, 0.2, 1.2, 0.6, 0.8, 0.1],
                    type: 'line',
                    smooth: true
                    }
                ]
                };
                myChart.setOption(option);
            },
            
        },
    }
</script>

<style lang="less" scoped>
    .echarts_home {
        //   width: 500px;
        height: 30rem;
        margin: auto;
        padding: 2rem 0rem 2rem 2rem;
        // border: 0.1rem solid lightcoral;

        //  宽高是必须给的，可以给百分比、具体的像素等....
        .barChart {
            width: 100%;
            height: 100%;
        }
    }

    .vanCell {
        padding: 8rem 2rem 2rem 2rem;
        background-image: url('http://cdnoss.ksyt.com.cn/wxImages/assets/images/tbg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        color: #fff;
        display: flex;
        align-items: center;

            .nickname{
                color: #646566;
                font-size: 2rem !important;
                padding-left:2rem !important;
            }
    }

    .signOut {
        text-align: center;
        padding-top: 2rem;
        padding-bottom: 10rem;

        .van-button {
            width: 90%;
        }
    }
    .qshuju {
        margin: 0 auto;
        text-align: center;
        background: none;
        font-size: 1.6rem;
        .dayBox{
            line-height: 4rem;
            padding-bottom: 0.8rem;
            color: #333333 !important;
        }
    }


</style>
