<template>
    <div>
        <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
            <template #title>
                {{info.name}}
            </template>
        </van-nav-bar>
        <div style="padding: 6rem 0 22rem 0;">
            <van-form @failed="onFailed" class="school">
                <van-cell-group inset>
                    <van-field
                            v-model="value1"
                            name="name"
                            colon
                            disabled
                            label="服务老师"
                            placeholder=""

                    >
                        <template #input>
                            {{info.real_name}}
                        </template>
                    </van-field>
                    <van-field
                            v-model="value2"
                            name="tel"
                            colon
                            disabled
                            label="手机号码"
                            placeholder=""

                    >
                        <template #input>
                            {{info.phone}}
                        </template>
                    </van-field>
                    <van-field
                            v-model="value3"
                            name="wxNum"
                            colon
                            disabled
                            label="微信号"
                            placeholder=""

                    >
                        <template #input>
                            {{info.we_chat_code}}
                        </template>
                        <template #button v-if="info.we_chat_code">
                            <van-button size="small" type="info" @click="doCopy()">复制</van-button>
                        </template>
                    </van-field>
                </van-cell-group>
                <div class="wxImg" v-if="info.code != false || info.applet_img != false">
                    <van-image
                            width="30rem"
                            height="30rem"
                            fit="cover"
                            :src="info.code ? info.code : info.applet_img"
                    />
                </div>
            </van-form>


        </div>
    </div>
</template>

<script>
    import {Toast} from 'vant'
    import {branchInfo} from '@/request/api'

    // import Tips from '@/components/Tips'
    export default {
        name: 'Cart',
        metaInfo: {
            title: '我的校区', //设置页面title
            meta: [{                 //设置页面meta
                name: 'keyWords',
                content: '关键词'
            },
                {
                    name: "description",
                    content: "描述",
                },
            ]
        },
        data() {
            return {
                message: '15233667113aa',
              info: []
            }
        },
      created() {
        this.getInfo();
      },
        methods: {
            onBack() {
                history.back();
            },
            dataProcessing(val) {
                this.message = this.message
            },
            doCopy: function (val) {
                this.dataProcessing(val)
                this.$copyText(this.message).then(function (e) {
                    Toast('内容已复制')
                }, function (e) {
                    Toast('该内容不能复制')
                    console.log(e)
                })
            },
            getInfo() {
                let that = this;
                let branchId = that.getBranch()
              branchInfo({branchId})
                    .then(res => {
                        if (res.data.code == 1) {
                          console.log(res.data);
                          that.info = res.data.data.data;
                          console.log(that.info.applet_img);
                        }
                    })
                    .catch(err => {
                        Toast.fail('操作错误');
                    })
            },
        },
    }
</script>

<style lang="less" scoped>
    .school {
        padding: 1rem 1.5rem;

        .van-cell-group--inset {
            border-radius: 1rem;
        }

        .van-field {
            font-size: 2rem;
            line-height: 5rem;
            padding: 1rem 1.5rem;
        }

        .wxImg {
            padding: 2rem;
            text-align: center;
        }
    }

</style>